import ElasticSearchModel from 'src/model/elasticsearch/ElasticSearchModel'
import { clone } from 'quasar'

export default {
  name: 'ContatosTelefonicos',
  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (previousRoute && ['veiculos'].indexOf(previousRoute.name) >= 0) {
      // Usar o store do filtro anterior para passar dados da busca realizada anteriormente
    }
    if (!previousRoute) {
      // store.commit('cache/putLimpar')
      // cache da busca anterior somente ou evento
    }
    let modelElastic = new ElasticSearchModel()
    return modelElastic
      .buscarAnuncio(currentRoute.params.id)
      .then((res) => {
        let dados =
          res.hits.hits && res.hits.hits.length > 0
            ? res.hits.hits[0]._source
            : null
        // const arquivos360 = [
        //   { label: 'Fotos 360 Externas', url: '/uploads-app/OfGvYWyHBuXG8Rum-1603457400.webp' },
        //   { label: 'Fotos 360 Internas', url: '/uploads-app/JYn3WzCwCOiEZ9wm-1603457833.webp' }
        // ]
        const arquivos360 = []
        if (!dados.bem_arquivos) {
          dados.bem_arquivos = []
        }
        dados.bem_arquivos = dados.bem_arquivos.reduce((acc, atual) => {
          const obj = { url: atual }
          acc = acc.concat(obj)
          return acc
        }, [])
        dados.bem_arquivos = Object.assign(
          [],
          [...arquivos360, ...dados.bem_arquivos]
        )
        return store.commit('data/putData', dados)
      })
      .catch((error) => {
        console.error('error preFetch: ', error)
      })
  },
  computed: {
    dados: {
      get: function () {
        let dados = clone(this.$store.getters['data/getData'])

        function ordenar (a, b) {
          if (a) a = a[0].toUpperCase() + a.substr(1)
          if (b) b = b[0].toUpperCase() + b.substr(1)
          return a < b ? -1 : a > b ? 1 : 0
        }

        if (
          dados &&
          dados.acessorios_busca_nome &&
          dados.acessorios_busca_nome.length > 0
        ) {
          dados.acessorios_busca_nome =
            dados.acessorios_busca_nome.sort(ordenar)
        }

        return dados
      },
      set: function (value) {
        this.data = Object.assign({}, this.data, value)
      }
    },
    formattedPhoneNumber () {
      const phoneNumber = this.dados.proprietario_local.numero_comercial
      const formattedNumber = phoneNumber.replace(/\D/g, '')
      const formattedPhoneNumber = formattedNumber.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
      return formattedPhoneNumber
    },
    comercialPhoneLink () {
      return `tel:+55${this.dados.proprietario_local.numero_comercial}`
    }
  }
}
